import React from 'react';
import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';


export default function EmailTable({ emails, onRemove, type }) {
  return (
    <TableContainer component={Paper} elevation={2}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>id</TableCell>
            <TableCell>From</TableCell>
            <TableCell>Subject</TableCell>
            {type === 'tracked' && (
              <>
                <TableCell>Status</TableCell>
                <TableCell>Reward Status</TableCell>
              </>
            )}
            <TableCell align="right">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {emails.length === 0 ? (
            <TableRow>
              <TableCell colSpan={type === 'tracked' ? 6 : 4} align="center">
                <Typography color="textSecondary">
                  {type === 'tracked'
                    ? 'No tracked emails yet'
                    : 'No draft emails available'}
                </Typography>
              </TableCell>
            </TableRow>
          ) : (
            emails.map((email, index) => (
              <TableRow key={index} hover>
                <TableCell>{email.id}</TableCell>
                <TableCell>{email.from_}</TableCell>
                <TableCell>{email.subject}</TableCell>
                <TableCell align="right">
                  <IconButton
                    size="small"
                    onClick={() => onRemove?.(email)}
                    color="error"
                  >
                    <AddIcon size={18} />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}