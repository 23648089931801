import React from 'react';
import { Box, Grid, Paper, Typography } from '@mui/material';

const CampaignStatistics = ({ campaign }) => {
  return (

    <Box mb={4}>
      <Paper elevation={1} sx={{ p: 2, textAlign: 'center' }}>
        <Grid container spacing={3}>
          {[
            { label: 'Emails Tracked', value: campaign.metrics.tracked },
            { label: 'Emails Sent', value: campaign.metrics.sent },
            { label: 'Emails Replied', value: campaign.metrics.replied },
            { label: 'Rewards Missed', value: campaign.metrics.rewards_missed },
            { label: 'Rewards Sent', value: campaign.metrics.rewards_earned },
            { label: 'Rewards Redeemed', value: campaign.metrics.rewards_redeemed },
          ].map((summary, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Box elevation={1} sx={{ p: 2, textAlign: 'center' }}>
                <Typography variant="body2" color="textSecondary" sx={{ fontWeight: 'bold' }}>
                  {summary.label}
                </Typography>
                <Typography variant="h6" color="primary">
                  {summary.value}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Paper>
    </Box>

  );
};

export default CampaignStatistics;