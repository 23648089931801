import React, { useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Switch, Typography } from '@mui/material';
import { updateCampaign } from '../../services/api/ApiService';

const IncentiveMentionedPopup = ({ campaign, setCampaign }) => {
  const [popupOpen, setPopupOpen] = useState(false);
  const [livePopupOpen, setLivePopupOpen] = useState(false);
  const [showCopyMsg, setShowCopyMsg] = useState(false);
  const exampleText =
    'We use a service called Speedable to accelerate email comms. Reply within [time] to receive [reward].';

  useEffect(() => {
    if (!popupOpen) {
      document.activeElement.blur();
    }
  }, [popupOpen]);

  useEffect(() => {
    if (!livePopupOpen) {
      document.activeElement.blur();
    }
  }, [livePopupOpen]);
  const handleCopyText = () => {
    navigator.clipboard.writeText(exampleText);
    setShowCopyMsg(true);
    setTimeout(() => {
      setShowCopyMsg(false);
      setPopupOpen(false);
    }, 750);
  };
  const handleIncentiveToggle = async (incentive_mentioned) => {
    setCampaign({ ...campaign, incentive_mentioned: incentive_mentioned });
    updateCampaign(campaign.id, { incentive_mentioned: incentive_mentioned }).then(null);
  };
  return (
    <>
      <Dialog open={popupOpen} onClose={() => setPopupOpen(false)}>
        <DialogTitle>Example Text</DialogTitle>
        <DialogContent>
          <Typography>{exampleText}</Typography>
          {showCopyMsg && <Typography sx={{ color: 'green' }}>Copied to clipboard!</Typography>}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCopyText} color="primary">
            Copy to Clipboard
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={livePopupOpen} onClose={() => setLivePopupOpen(false)}>
        <DialogTitle>Campaign Live</DialogTitle>
        <DialogContent>
          <Typography>You can now send your email drafts.</Typography>
          <Typography>If your recipients meet your deadline, we'll send them their rewards</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setLivePopupOpen(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Switch
        checked={campaign.incentive_mentioned || false}
        onChange={() => handleIncentiveToggle(!campaign.incentive_mentioned)}
        color="primary"
      />
      <Button
        variant="text"
        color="primary"
        onClick={() => setPopupOpen(true)}
      >
        Click for example
      </Button>
    </>
  );
};

export default IncentiveMentionedPopup;