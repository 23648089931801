import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Link } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

const BackLink = ({ path, text = 'Back' }) => {
  const navigate = useNavigate();
  return (
    <Box
      display="flex"
      alignItems="center"
      sx={{ cursor: 'pointer', color: 'primary.main', fontWeight: 'bold' }}
      onClick={() => navigate(path)}
    >
      <ChevronLeftIcon fontSize="small" sx={{ mr: 1, color: 'inherit' }} />
      <Link underline="none" color="inherit">
        {text}
      </Link>
    </Box>
  );
};

export default BackLink;