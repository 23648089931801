import React, { useEffect, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import CheckoutForm from '../components/CheckoutForm';
import {
  deletePaymentMethod,
  getPaymentMethods,
  postRedeemVoucher,
  savePaymentMethod,
  setDefaultPaymentMethod,
} from '../services/api/ApiService'; // Update the path as needed
import { Box, Button, Card, CardContent, Grid, TextField, Typography } from '@mui/material';
import { useAuth } from '../services/auth/AuthService';

const stripePromise = loadStripe(process.env.STRIPE_PUBLIC_KEY);

const Billing = () => {
  const [voucherCode, setVoucherCode] = useState('');
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [errorMsg, setErrorMessage] = useState(null);
  const [successMsg, setSuccessMessage] = useState(null);
  const [busy, setBusy] = useState(false);
  const { syncUserCredits } = useAuth();
  const options = {
    clientSecret: process.env.STRIPE_SECRET_KEY,
  };
  const fetchPaymentMethods = async () => {
    try {
      const response = await getPaymentMethods();
      if (response.data) {
        setPaymentMethods(response.data);
      }
    } catch (error) {
      console.error('Error fetching payment methods:', error);
    }
  };

  useEffect(() => {
    fetchPaymentMethods();
  }, []);

  const handleSavePaymentMethod = async (paymentMethodId) => {
    try {
      await savePaymentMethod(paymentMethodId);
      await fetchPaymentMethods(); // Refresh list after adding
      console.log('Payment method saved successfully');
    } catch (error) {
      console.error('Error saving payment method:', error);
    }
  };

  const handleDeletePaymentMethod = async (paymentMethodId) => {
    try {
      const response = await deletePaymentMethod(paymentMethodId);
      if (response.message) {
        alert(response.message); // Notify success
      }
      await fetchPaymentMethods(); // Refresh the list after deletion
    } catch (error) {
      if (error.response && error.response.data) {
        const { message, reason, campaigns } = error.response.data.detail;
        if (reason) {
          alert(
            `${message}\n\nReason: ${reason}\n\nLive Campaigns:\n${campaigns.join(', ')}`,
          );
        } else {
          alert('An error occurred while deleting the payment method.');
        }
      } else {
        console.error('Error deleting payment method:', error);
        alert('Failed to delete payment method.');
      }
    }
  };


  const handleApplyVoucher = async (e) => {
    e.preventDefault();
    try {
      setBusy(true);
      if (!voucherCode) {
        setErrorMessage('Please enter a voucher code');
        return;
      }
      await postRedeemVoucher(voucherCode);
      syncUserCredits();
      setVoucherCode('');
      setSuccessMessage('Voucher redeemed successfully');
    } catch (err) {
      console.error('Error redeeming voucher:', err);
      setErrorMessage(err?.message ?? 'Unable to redeem voucher');
    } finally {
      setTimeout(() => {
        setSuccessMessage(null);
        setErrorMessage(null);
      }, 5000);
      setBusy(false);
    }
  };

  return (
    <Box sx={{ flexGrow: 1, padding: 3 }}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Enter Voucher Code
              </Typography>
              <form onSubmit={handleApplyVoucher} noValidate>
                <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="voucherCode"
                    label="Voucher Code"
                    name="voucherCode"
                    autoFocus
                    disabled={busy}
                    value={voucherCode}
                    onChange={(e) => setVoucherCode(e.target.value?.toUpperCase())}
                    sx={{ zIndex: 0 }}
                  />

                  {successMsg && (
                    <div
                      style={{
                        marginTop: 16,
                        padding: 8,
                        fontWeight: 'bold',
                        fontSize: 12,
                        color: 'green',
                        backgroundColor: '#DFF2BF',
                        borderRadius: 8,
                      }}
                    >
                      {' '}
                      {successMsg}
                    </div>
                  )}
                  {errorMsg && (
                    <div
                      style={{
                        marginTop: 16,
                        padding: 8,
                        fontWeight: 'bold',
                        fontSize: 12,
                        color: 'red',
                        backgroundColor: '#FFCDD2',
                        borderRadius: 8,
                      }}
                    >
                      {' '}
                      {errorMsg}
                    </div>
                  )}
                  <Button
                    disabled={busy}
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    style={{ marginTop: 24 }}
                  >
                    Apply
                  </Button>
                </Box>
              </form>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Add New Payment Method
              </Typography>
              <Elements stripe={stripePromise} options={options}>
                <CheckoutForm onSavePaymentMethod={handleSavePaymentMethod} />
              </Elements>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          {paymentMethods.map((pm) => (
            <Card key={pm.id} sx={{ marginBottom: 2 }}>
              <CardContent id="payment-card">
                <Typography variant="h6" gutterBottom>
                  Card: **** **** **** {pm.card.last4}
                </Typography>
                <Typography variant="body1">Type: {pm.card.display_brand || pm.card.brand}</Typography>
                <Typography variant="body2">
                  Expiry: {pm.card.exp_month}/{pm.card.exp_year}
                </Typography>
                <Button
                  variant="contained"
                  color="error"
                  onClick={() => handleDeletePaymentMethod(pm.id)}
                  sx={{ marginRight: 1 }}
                >
                  Delete
                </Button>
                <Button variant="contained" onClick={() => setDefaultPaymentMethod(pm.id)}>
                  Set as Default
                </Button>
              </CardContent>
            </Card>
          ))}
        </Grid>
      </Grid>
    </Box>
  );
};

export default Billing;
