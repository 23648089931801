import React from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import * as Sentry from '@sentry/react';
import { AuthProvider } from './services/auth/AuthService'; // Adjust the import path as necessary
import PrivateLayout from './layouts/PrivateLayout';

import LoginPage from './routes/Login';
import LogoutPage from './routes/Logout';
import SignUpPage from './routes/SignUp';
import PrivacyPolicyPage from './routes/PrivacyPolicy';
import TermsOfServicePage from './routes/TermsOfService';
import RedeemPage from './routes/Redeem';
import Billing from './routes/Billing';
import Dashboard from './routes/Dashboard';
import Profile from './routes/Profile';
import Integrations from './routes/Integrations';
import Campaigns2 from './routes/Campaigns2';
import GoogleOAuthRedirectStart from './routes/integrations/google/GoogleOAuthRedirectStart';
import GoogleOAuthRedirectEnd from './routes/integrations/google/GoogleOAuthRedirectEnd';

import CampaignDetail from './routes/campaigns/CampaignDetail';
import CampaignCreation from './components/CampaignCreation';

import ThreadDetail from './routes/threads/ThreadDetail';
import ForgotPassword from './routes/ForgotPassword';
import Rewards from './routes/Rewards';
import GoogleOAuthRefreshRedirectStart from './routes/integrations/google/GoogleOAuthRefreshRedirectStart';
import GoogleOAuthRemoveRedirectStart from './routes/integrations/google/GoogleOAuthRemoveRedirectStart';
import CampaignDetailV2 from './routes/campaigns/CampaignDetail2';

const NotFoundPage = () => <h1>404 - Page Not Found</h1>;

// Define your theme here
const theme = createTheme({
  palette: {
    primary: {
      main: '#0052ff',
    },
    secondary: {
      main: '#e9e9ff',
    },
    error: {
      main: '#E39774',
    },
    background: {
      default: '#e9e9ff',
      paper: '#FFFFFF',
    },
  },
});

Sentry.init({
  dsn: 'https://5a1d542f0b90e29f1d5d45b6039405f2@o4507974655213568.ingest.de.sentry.io/4507974753452112',
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

function App() {
  return (
    // Use ThemeProvider here to make the theme available to your app
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/login" element={<LoginPage />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/signup" element={<SignUpPage />} />
            <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
            <Route path="/terms-of-service" element={<TermsOfServicePage />} />
            <Route path="/redeem/:campaignId/:threadId/:recipientId" element={<RedeemPage />} />
            <Route element={<PrivateLayout />}>
              <Route path="/logout" element={<LogoutPage />} />
              <Route path="/" element={<Navigate to="/campaigns" replace />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/rewards" element={<Rewards />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/integrations" element={<Integrations />} />
              <Route path="/campaigns" element={<Campaigns2 />} />
              <Route path="/campaigns2" element={<Campaigns2 />} />
              <Route path="/campaigns/create" element={<CampaignCreation />} />
              <Route path="/campaigns/:id" element={<CampaignDetail />} />
              <Route path="/campaigns2/:id" element={<CampaignDetailV2 />} />
              <Route path="/threads/:id" element={<ThreadDetail />} />
              <Route path="/billing" element={<Billing />} />
              <Route path="/google/begin-integration" element={<GoogleOAuthRedirectStart />} />
              <Route path="/google/refresh-integration" element={<GoogleOAuthRefreshRedirectStart />} />
              <Route path="/google/remove-integration/:integrationId" element={<GoogleOAuthRemoveRedirectStart />} />
              <Route path="/google/integration-complete" element={<GoogleOAuthRedirectEnd />} />
            </Route>
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </BrowserRouter>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;
