import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AppBar, Box, Button, CircularProgress, Tab, Tabs } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';
import BackLink from '../../components/BackLink';
import CampaignHeader from './CampaignHeader';
import { getCampaignV2 } from '../../services/api/ApiService';
import CampaignStatistics from './CampaignStatistics';
import CampaignEmailTracking from './CampaignEmailTracking';


const emptyCampaign = {
  id: '',
  name: '',
  status: '',
  created: '',
  metrics: {
    drafted: 0,
    sent: 0,
    replied: 0,
    rewards_earned: 0,
    rewards_redeemed: 0,
    rewards_missed: 0,
  },
  emails: [],
  incentives: [],
  incentive_mentioned: false,
  incentive_deadline: '',
  charge_on: '',
  payment_method: '',
};
const CampaignDetailV2 = () => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [isDraftsOpen, setIsDraftsOpen] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const [campaign, setCampaign] = useState(emptyCampaign);

  const loadData = async () => {
    setIsLoading(true);
    try {
      const campaignData = await getCampaignV2(id);
      setCampaign(campaignData);
    } catch (error) {
      console.error('Failed to fetch drafts:', error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    loadData().then(null);
  }, []);

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  return (
    <>
      {isLoading && <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>}
      <BackLink path="/campaigns" />
      <CampaignHeader campaign={campaign} setCampaign={setCampaign} />
      <CampaignStatistics campaign={campaign} />

      {/* Tabs Section */}
      <AppBar position="static" color="default" elevation={0}>
        <Box flexDirection="row" display="flex" justifyContent="space-between" alignItems="center">
          <Tabs
            value={tabIndex}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab label="Email Tracking" />
            {/*<Tab label="Settings" />*/}
          </Tabs>
          <Button
            onClick={() => setIsDraftsOpen(!isDraftsOpen)}
            variant="contained"
            color="primary"
            startIcon={<FontAwesomeIcon icon={faGoogle} />}
            sx={{ '&:hover': { backgroundColor: '#357ae8' }, mx: 2 }}
          >
            Add Draft
          </Button>
        </Box>
      </AppBar>

      {tabIndex === 0 && (
        <CampaignEmailTracking campaign={campaign} isDraftsOpen={isDraftsOpen} setIsDraftsOpen={setIsDraftsOpen}
                               setCampaign={setCampaign} />)}
      {/*{tabIndex === 1 && (<CampaignIncentives campaign={campaign} />)}*/}
    </>
  );
};

export default CampaignDetailV2;