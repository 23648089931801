import React, { useEffect } from 'react';
import { Box, CircularProgress, Dialog, DialogContent, DialogTitle } from '@mui/material';
import EmailTable from './EmailTable';


export default function DraftsList({
                                     open,
                                     isLoading,
                                     onClose,
                                     drafts,
                                     onTrackEmail,
                                   }) {
  useEffect(() => {
    if (!open) {
      // Reset focus to a specific element or body
      document.activeElement.blur();
    }
  }, [open]);

  return (
    <>

      <Dialog
        open={open}
        onClose={onClose}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Available Draft Emails</DialogTitle>
        <DialogContent>
          {isLoading ? <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 200 }}>
              <CircularProgress />
            </Box> :
            <Box sx={{ mt: 2 }}>
              <EmailTable
                emails={drafts}
                onRemove={onTrackEmail}
                type="drafts"
              />
            </Box>
          }
        </DialogContent>
      </Dialog>
    </>
  );
}