import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Cancel as CancelIcon, CheckCircle as CheckIcon } from '@mui/icons-material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';
import { faCheckCircle, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { useAuth } from '../services/auth/AuthService';
import { getIntegrations } from '../services/api/ApiService'; // Adjust the import path as necessary

function Integrations() {
  const navigate = useNavigate();
  const { userDetails } = useAuth(); // Access userDetails via useAuth
  const [integrations, setIntegrations] = useState([]);
  const [googleIntegrations, setGoogleIntegrations] = useState([]);
  const [isGoogleIntegrated, setIsGoogleIntegrated] = useState(false);
  const [isGoogleExpanded, setIsGoogleExpanded] = useState(false);

  const fetchIntegrations = async () => {
    const integrations = await getIntegrations();
    setIntegrations(integrations.data);
  };
  useEffect(() => {
    fetchIntegrations().then(null);
  }, []);

  useEffect(() => {
    if (integrations.length > 0) {
      const googleIntegrations = integrations.filter(
        (integration) => integration.type === 'gmail' && integration.status === 'active',
      );
      setGoogleIntegrations(googleIntegrations);
    }
  }, [integrations]);

  useEffect(() => {
    if (googleIntegrations.length > 0) {
      setIsGoogleIntegrated(true);
      setIsGoogleExpanded(true);
    }
  }, [googleIntegrations]);

  const handleAccordionChange = () => {
    setIsGoogleExpanded(!isGoogleExpanded);
  };

  const handleGoogleIntegration = () => {
    navigate('/google/begin-integration');
  };
  const handleGoogleReintegration = (email) => {
    const query = email ? `?email=${encodeURIComponent(email)}` : '';
    navigate(`/google/refresh-integration${query}`);
  };
  const handleRemoveIntegration = async (integrationId) => {
    navigate('/google/remove-integration/' + integrationId);
  };

  return (
    <Box>
      <Typography variant="h4" component="h1" sx={{ p: 2 }}>
        Integrations
      </Typography>
      <Typography variant="body1" component="h1" sx={{ p: 2 }}>
        Here you can manage the integrated services you can use speedable with.
      </Typography>
      <Box sx={{ p: 2 }}>
        {/*<Accordion>*/}
        <Accordion expanded={isGoogleExpanded} onChange={handleAccordionChange}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
            <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
              <Typography>
                <FontAwesomeIcon icon={faEnvelope} style={{ marginRight: 8 }} /> Gmail
              </Typography>
              {isGoogleIntegrated && (
                <Box id="google-integration" display="flex" alignItems="center" sx={{ marginRight: 2 }}>
                  <Typography variant="subtitle1" component="span" sx={{ mr: 1 }}>
                    Integrated
                  </Typography>
                  <FontAwesomeIcon icon={faCheckCircle} size="lg" color="green" />
                </Box>
              )}
            </Box>
          </AccordionSummary>
          <AccordionDetails>


            <Box>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell><strong>Email</strong></TableCell>
                    <TableCell><strong>Last Sync</strong></TableCell>
                    <TableCell align="center"><strong>Health</strong></TableCell>
                    <TableCell><strong>Error</strong></TableCell>
                    <TableCell align="right"><strong>Actions</strong></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {googleIntegrations.map((integration) => (
                    <TableRow key={integration.id}>
                      <TableCell>{integration.email}</TableCell>
                      <TableCell>
                        {integration.last_active
                          ? `${new Date(integration.last_active).toLocaleString()}`
                          : 'No sync history'}
                      </TableCell>
                      <TableCell align="center">
                        {integration.is_healthy ? (
                          <CheckIcon color="success" />
                        ) : (
                          <CancelIcon color="error" />
                        )}
                      </TableCell>
                      <TableCell>
                        {integration.error || 'No issues'}
                      </TableCell>
                      <TableCell align="right">
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                            gap: 1, // Adds spacing between buttons
                          }}
                        >
                          <Button
                            variant="contained"
                            startIcon={<FontAwesomeIcon icon={faGoogle} style={{ color: 'white' }} />}
                            onClick={() => handleGoogleReintegration(integration.email)}
                            size="small"
                            sx={{
                              backgroundColor: '#1a73e8',
                              color: 'white',
                              minWidth: '100px', // Ensures consistent button width
                              '&:hover': {
                                backgroundColor: '#135ba1',
                              },
                            }}
                          >
                            Refresh
                          </Button>

                          <Button
                            variant="outlined"
                            color="error"
                            onClick={() => handleRemoveIntegration(integration.id)}
                            size="small"
                            sx={{
                              minWidth: '100px', // Ensures consistent button width
                            }}
                          >
                            Remove
                          </Button>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>

              <Box mt={2}>
                <Button
                  variant="contained"
                  startIcon={<FontAwesomeIcon icon={faGoogle} style={{ color: 'white' }} />}
                  onClick={() => handleGoogleIntegration()}
                  sx={{
                    backgroundColor: '#1a73e8',
                    color: 'white',
                    '&:hover': {
                      backgroundColor: '#135ba1',
                    },
                  }}
                >
                  Add another Gmail account
                </Button>
              </Box>
            </Box>
          </AccordionDetails>
        </Accordion>
        {/*<Accordion>*/}
        {/*  <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">*/}
        {/*    <Typography>*/}
        {/*      <FontAwesomeIcon icon={faHubspot} style={{ marginRight: 8 }} /> HubSpot*/}
        {/*    </Typography>*/}
        {/*  </AccordionSummary>*/}
        {/*  <AccordionDetails>*/}
        {/*    <Typography>HubSpot integration coming soon.</Typography>*/}
        {/*  </AccordionDetails>*/}
        {/*</Accordion>*/}
        {/*<Accordion>*/}
        {/*  <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3a-content" id="panel3a-header">*/}
        {/*    <Typography>*/}
        {/*      <FontAwesomeIcon icon={faMailchimp} style={{ marginRight: 8 }} /> MailChimp*/}
        {/*    </Typography>*/}
        {/*  </AccordionSummary>*/}
        {/*  <AccordionDetails>*/}
        {/*    <Typography>MailChimp integration coming soon.</Typography>*/}
        {/*  </AccordionDetails>*/}
        {/*</Accordion>*/}
      </Box>
    </Box>
  );
}

export default Integrations;
